import React, { useState, useEffect } from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Container from '../components/Container'
import Btn from '../components/Btn'


const PrivacyPage = () => {

  const [toggler, setToggler] = useState(false);

  return (
    <Layout>
      <Seo title="Privacy Policy" />
	  <Container Slim>
	  	<h1 className="text-6xl mb-2 font-medium">Privacy Policy</h1>
		<p>Your privacy is important to us – so, the only personal information we collect is the personal information you provide and we are committed to safeguarding that information.</p>
		<p><strong>Effective Date:</strong>&nbsp;This privacy policy is effective as of October 1, 2020.</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Scope Of This Privacy Policy: </h4>
		<p>This privacy policy applies to your use of the CLK Project Management website and the applications CLK Project Management provides on various third-party marketplaces. It does not apply to your use of other sites or services, and it does not apply to information we collect through other methods or sources such as sites owned or operated by our affiliates or vendors. Please be sure to review the privacy policy of any site, service, or application that you use as privacy practices often differ.The personal information we collect on this website is the information you provide to CLK Project Management. This information may include, for example, your name, email address, or phone number. In some cases, you may provide it directly by entering information into an application, survey, or online form, and in some cases, you may provide it indirectly, like the IP address of the computer or device you use to connect with us online.</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Collection of Information: </h4>
		<p>We collect and may use information about you to:</p>
		<ul>
		<li>Comply with federal, state, and local laws</li>
		<li>Provide information you have requested</li>
		<li>Perform analytics and other value-added services</li>
		<li>Verify information and combat fraud</li>
		<li>Send you other information about CLK Project Management we believe you would find interesting</li>
		</ul>
		<p>We do not license, sell, or rent any collected information, including contact information or specific information about your accounts or other personally identifiable information unless: (1) you request or authorize it; (2) information is provided to help complete a transaction initiated by you; or (3) the disclosure is otherwise lawfully permitted or requested. We may disclose personally identifiable information we collect from you to a third-party website analytics company. When you visit our site, we will share your IP address to the extent necessary in order for the third party to provide that service.</p>
		<p>This website does not maintain a process by which you can review and make changes to the personally identifiable information we collect from you.</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Use of Cookies: </h4>
		<p>A cookie is a small amount of data, which often includes an anonymous unique identifier, that is sent to your browser from a website and stored on your computer. The cookies we use do not contain or capture unencrypted personal information. We use cookies to:</p>
		<ul>
		<li>Display information more effectively</li>
		<li>Personalize your experience while engaging with us</li>
		<li>Facilitate navigation and track the effectiveness of our marketing programs</li>
		<li>Help recognize your device, so you’re able to use our online products and services</li>
		</ul>
		<p>You can configure your browser to accept or reject cookies. If you reject all cookies, you may not be able to use our products or services that require you to sign in, and you may not be able to take full advantage of all online products and services. We do not allow third parties to collect personally identifiable information about a user’s online activities, over time and across different sites, services, and applications, when that user uses our site, service, or application. Because CLK Project Management, LLC does not track its customers over time and across third party websites to provide targeted advertising it, therefore, does not respond to Do Not Track (DNT) signals.</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Security Measures: </h4>
		<p>We have implemented several security measures to protect your information. Your information is protected on secured networks and is accessible by a limited number of employees who have special access rights to such systems. Industry-standard encryption technologies are used when transferring or receiving sensitive information supplied by users, and we regularly work to improve information security and to ensure information integrity.</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Third-Party Links: </h4>
		<p>We may create links to third-party websites. We are not responsible for the privacy practices employed by any third-party websites and our privacy policies and practices do not apply to these sites. We do not guarantee, approve, or endorse any information, material, services, or products contained on or available through any linked websites. These linked sites are for your convenience and you access them at your own risk. Nevertheless, we seek to protect the integrity of our website and the links placed upon the site and therefore request any user feedback – not only for our own site, but for sites we link to (e.g., broken links, 404 errors, etc.).</p>
		<h4 className="text-xl font-medium mb-3 mt-7">Modifications to Privacy Policy: </h4>
		<p>Occasionally, we may make changes to this Privacy Policy. If we do, we will post the revised version here. You should periodically check here for the most up-to-date version of this Privacy Policy. Any changes to the Privacy Policy will not be retroactively applied and will not alter how we handle personally identifiable information we previously collected from you.</p>


		<div style={{paddingBottom: '6rem', paddingTop: '1rem'}}>
		<Btn linkTo={`/#contact`} buttonTitle="Contact Us" isLink />
		</div>
	  </Container>
    </Layout>
  )
}

export default PrivacyPage;
